<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  Bitwarden Web Vault<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
    
    
   
  </div>
</div>
